<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon small>
            <v-icon small>fal fa-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit mailbox note or set user on hold</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Edit Mailbox Settings for Box {{ item.box }}</v-card-title>
      <v-card-text>
        <v-switch v-model="active" :label="'Mailbox is ' + (active ? 'Active' : 'Inactive')"></v-switch>
        <v-textarea v-model="note" label="Mailbox Note" rows="3" auto-grow outlined hide-details></v-textarea>
        <v-checkbox v-for="({ name }, index) in users" :key="'user-' + index" v-model="users[index].hold" :label="'Hold this mailbox for ' + name" hide-details></v-checkbox>
        <v-dialog v-model="addUserDialog" width="400">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" style="margin-top:1em">Add User to Mailbox</v-btn>
          </template>
          <v-card>
            <v-card-title>Add User to Box {{ item.box }}</v-card-title>
            <v-card-text>
              <directory-search :include-photo="false" :clearable="true" :filter="filter" search-label="User Search" @clear="userToAdd._id = ''" @select="(person) => selectUser(person)"></directory-search>
            </v-card-text>
            <v-card-actions>
              <v-btn color="success" text @click="addUser">Add User</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="addUserDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-btn text color="success" @click="save()">Save</v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="note === ''" color="error" @click="clearNote()">Clear Note</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    directorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root, emit }) {
    const service = root.$feathers.service('mailroom/mailbox')
    const dialog = ref(false)
    const active = ref(true)
    const note = ref('')
    const users = ref([])
    watch(() => props.item, () => {
      note.value = props.item.note || ''
      active.value = props.item.active
      users.value = []
      for (const { hold, name } of props.item.users) {
        users.value.push({ hold, name })
      }
    })

    const addUserDialog = ref(false)
    const usersWithBox = ref([])
    const filter = computed(() => {
      return { pidm: { $nin: usersWithBox.value } }
    })
    const userToAdd = ref({
      pidm: '',
      bannerId: '',
      name: '',
      personType: ''
    })
    function selectUser ({ value }) {
      if (value) {
        root.$feathers.service('directory/people').get(value).then((data) => {
          userToAdd.value = {
            pidm: data.pidm,
            bannerId: data.bannerId,
            name: data.name.last + ', ' + data.name.first,
            personType: data.person
          }
          console.log(userToAdd.value)
        })
      }
    }
    async function addUser () {
      try {
        await root.$feathers.service('mailroom/mailbox').patch(props.item._id, { $push: { users: { ...userToAdd.value, action: 'add' } } })
        emit('reload')
        addUserDialog.value = false
        active.value = false
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'User added to mailbox.' })
      } catch (e) {
        console.error(e)
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 8000, text: 'Error adding user: ' + e })
      }
    }
    onMounted(async () => {
      const { total } = await service.find({ query: { empty: false, $limit: 0 } })
      for (let i = 0; i < total; i += 50) {
        const { data } = await service.find({ query: { empty: false, $limit: 50, $skip: i } })
        for (const { users } of data) {
          users.forEach(({ pidm }) => usersWithBox.value.push(pidm))
        }
      }
    })

    async function save () {
      try {
        let obj = { }
        let changed = false
        for (let i = 0; i < props.item.users.length; i++) {
          if (props.item.users[i].hold !== users.value[i].hold) {
            obj['users.' + i + '.hold'] = users.value[i].hold
            changed = true
          }
        }
        if (note.value !== props.item.note) {
          obj.note = note.value
          changed = true
        }
        if (active.value !== props.item.active) {
          obj.active = active.value
          changed = true
        }
        if (!changed) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'No changes to save.' })
          return
        }
        await service.patch(props.id, obj)
      } catch (e) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error saving changes: ' + e })
        return
      }
      root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Mailbox details saved successfully' })
      dialog.value = false
      emit('reload')
    }

    async function clearNote () {
      try {
        await service.patch(props.id, { note: '' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error clearing note: ' + e })
        return
      }
      root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Note cleared successfully' })
      dialog.value = false
      emit('reload')
    }

    return {
      dialog,
      active,
      note,
      addUser,
      users,
      addUserDialog,
      usersWithBox,
      filter,
      userToAdd,
      selectUser,
      save,
      clearNote
    }
  }
}
</script>
