var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, dialogOn, tooltipOn)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit mailbox note or set user on hold")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Mailbox Settings for Box "+_vm._s(_vm.item.box))]),_c('v-card-text',[_c('v-switch',{attrs:{"label":'Mailbox is ' + (_vm.active ? 'Active' : 'Inactive')},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}),_c('v-textarea',{attrs:{"label":"Mailbox Note","rows":"3","auto-grow":"","outlined":"","hide-details":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_vm._l((_vm.users),function(ref,index){
var name = ref.name;
return _c('v-checkbox',{key:'user-' + index,attrs:{"label":'Hold this mailbox for ' + name,"hide-details":""},model:{value:(_vm.users[index].hold),callback:function ($$v) {_vm.$set(_vm.users[index], "hold", $$v)},expression:"users[index].hold"}})}),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"1em"}},on),[_vm._v("Add User to Mailbox")])]}}]),model:{value:(_vm.addUserDialog),callback:function ($$v) {_vm.addUserDialog=$$v},expression:"addUserDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add User to Box "+_vm._s(_vm.item.box))]),_c('v-card-text',[_c('directory-search',{attrs:{"include-photo":false,"clearable":true,"filter":_vm.filter,"search-label":"User Search"},on:{"clear":function($event){_vm.userToAdd._id = ''},"select":function (person) { return _vm.selectUser(person); }}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.addUser}},[_vm._v("Add User")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addUserDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],2),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.note === '',"color":"error"},on:{"click":function($event){return _vm.clearNote()}}},[_vm._v("Clear Note")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }